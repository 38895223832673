/* Color Theme Swatches in Hex */
/*.Architecture-1-hex {color: #6B87BF;} violet*/
/*.Architecture-2-hex {color: #99B5D8;} light blue*/
/*.Architecture-3-hex {color: #3B3F18;} dark brown*/
/*.Architecture-4-hex {color: #858C55;} gold*/
/*.Architecture-5-hex {color: #F2F2F2;} off white*/

.main-container {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 60px; /* height of your footer */
}

.background-color {
  background-color: #F2F2F2;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color:  #99B5D8;
  color: #ffffff
}

footer a:link,
footer a:visited{
  color: #ffffff;
}

footer a:hover {
  color: #a5be00;
}
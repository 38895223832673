.analysis-container {
    display: flex;
    flex-direction: column;
    padding: 2px;
}

.grid-wrapper {
  align-self: center;
    width: 100%;
    height: 450px;
    min-height: 400px;
}

.chart-wrapper {
   align-self: center;
    padding:0 5px 0 5px;
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.myGrid {

}
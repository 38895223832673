/* Color Theme Swatches in Hex */
/*.Architecture-1-hex {color: #6B87BF;} violet*/
/*.Architecture-2-hex {color: #99B5D8;} light blue*/
/*.Architecture-3-hex {color: #3B3F18;} dark brown*/
/*.Architecture-4-hex {color: #858C55;} gold*/
/*.Architecture-5-hex {color: #F2F2F2;} off white*/

.portfolio-collapse-container {
    width: 100%;
    max-width: 750px;
    margin: 0px 0 5px 0;
    min-height: 50px;
    border: 1px solid #858C55;
    background-color: #ffffff;
    border-radius: 5px;
}

.fund-details{
    display: flex;
   flex-direction: column;
    width: 100%;
    padding: 5px;
    border-top: 1px solid #6B87BF;
    /*border-bottom: 1px solid #6B87BF;*/
    background-color: #F2F2F2;
    color: #3B3F18;
    border-radius: 0 0 5px 0;
}

.fund-details:nth-child(2n) {
    background-color: #ffffff;
}

.fund-name {
    flex: 1;
    align-self: center;
    text-align: start;
    padding: 0 0 0 5px;
    min-width: 200px;
}
.fund-detail-group{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    min-width: 225px;
}

.fund-detail {
    display: flex;
    text-align: start;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
}

.fund-detail:nth-child(2n) {
    background-color: #F2F2F2;
}

.fund-details-span,
.fund-details-span-left {
    font-weight: bold;
}

.fund-var{
    color: #858C55;
}

.download-button:link,
.download-button:visited {
    text-decoration: none;
    color: #ffffff;
}

.fund-details-link:link,
.fund-details-link:visited {
    text-decoration: none;
    color: #3B3F18;
}

.fund-details-link:hover {
    color: #99B5D8;
}

.fund-details-span-left {
    flex:1;
}

.fund-details-span-right {
    text-align: start;
    padding-left: 5px;
}

.collapse-btn {
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    background-color: #858C55;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.left {
    display: flex;
    align-items: center;
    flex: 1;
    align-self: center;
    font-size: 16px;
}

.right {
    align-self: center;
    margin-right: 10px;
}

.font-awesome-external-link{
    color: #6B87BF;
    margin: 0 10px 0 10px;
}

.font-awesome-chevron{
    flex: 1;
    align-self: center;
    color: #ffffff;
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

.delete-edit-button {
    margin: 2px 0 2px 0;
    background: transparent;
    border-style: solid;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 500px) {
    .fund-details {
        flex-direction: row;
        padding: 0;
    }
}


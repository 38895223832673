.portfolio-collection-section{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 5px 0 5px 0;

}

.collection-container{

}
.new-portfolio{
   align-self: center;
   margin-bottom: 20px ;
}

.font-awesome-arrow-down{
   flex: 1;
   margin-left: 5px;
   align-self: center;
   color: #ffffff;
}

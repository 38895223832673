.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registration-form {
    width: 80%;
    max-width: 550px;
}

.red {
    color: red;
}

.grid-style {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 20px 0 0 0;
}
/* Color Theme Swatches in Hex */
/*.Architecture-1-hex {color: #6B87BF;} violet*/
/*.Architecture-2-hex {color: #99B5D8;} light blue*/
/*.Architecture-3-hex {color: #3B3F18;} dark brown*/
/*.Architecture-4-hex {color: #858C55;} gold*/
/*.Architecture-5-hex {color: #F2F2F2;} off white*/

.Hyph {
  margin-right: 5px;
  margin-left: 5px;
}

.Input,
.Textarea {
  border: 1px solid #F2F2F2;
  display: block;
  font-size: 1.1em;
  margin-bottom: 5px;
  padding: 8px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.Input::placeholder,
.Textarea::placeholder {
  color: #ccc;
}

.Textarea {
  resize: vertical;
}

.Button {
  border: 1px solid #F2F2F2;
  background-color: #858C55;
  min-width: 120px;
  color: #ffffff;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: bold;
  cursor: pointer;
}

.Required {
  color: #CA1551;
  font-family: Arial;
  font-weight: bold;
}

.Section {
  margin-left: 20px;
  margin-right: 20px;
}
.Section--list {
  margin-left: 10px;
  margin-right: 10px;
}

.react-csv-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.csv-input {
  width: 75%;
  background-color: #858C55;
  /*border: 1px solid #6B87BF;*/
  /*border-radius: 5px;*/
}

.login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form {
    width: 80%;
    max-width: 550px;
}

.red {
    color: red;
}

